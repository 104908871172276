.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow:scroll;
  background-color: rgb(42 42 42);
  align-items: center;
  color: #fff;
}

label {
  color: #fff !important;
}

.AvatarContainer {
  flex-grow: 1;
}

.konvajs-content {
  max-height: 80vh;
}

.ClassProbabilitiesContainer {
  flex-shrink: 0;
  min-height: 100px;
}

.RendererContainer {
}

table {
  font-family: "Times New Roman", Times, serif;
  border: 1px solid #FFFFFF;
  width: 350px;
  height: 200px;
  text-align: center;
  border-collapse: collapse;
}
table td, table th {
  border: 1px solid #FFFFFF;
  padding: 3px 2px;
}
table tr:nth-child(even) {
  background: #888888;
}
table thead {
  background: #4A4A4A;
}
table thead th {
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #FFFFFF;
}
table thead th:first-child {
  border-left: none;
}
table tfoot td {
  font-size: 14px;
}